<template>
  <div>
    <CRow>
      <CCol sm="6">
        <CCard>
          <CCardHeader>
            <strong>Данные поверителя </strong><small></small>
          </CCardHeader>
          <CCardBody>
            <CInput
              label="ФИО"
              placeholder="Фамилия Имя Отчество"
              :value="$store.getters.user.name"
            />
            <CRow>
              <CCol sm="4">
                <CInput
                  label="Код партнера"
                  placeholder="Код партнера"
                  :value="$store.getters.user.partner.code"
                />
              </CCol>
              <CCol sm="8">
                <CInput
                  label="ID поверителя"
                  placeholder="Идентификатор поверителя"
                  :value="$store.getters.user.code"
                />
              </CCol>
            </CRow>
            <CInput
              label="Примечание"
              placeholder="Примечание"
              :value="$store.getters.user.comment"
            />
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Часовой пояс"
                  placeholder="Часовой пояс"
                  :value="$store.getters.user.hour_zone"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Тип"
                  placeholder="Тип"
                  :value="$store.getters.user.type"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6">
        <CCard>
          <CCardHeader>
            <strong>Средства измерений </strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CInput
                  label="Эталон"
                  placeholder="Эталон"
                  :value="$store.getters.user.ideal"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                  label="СИ, как эталон"
                  placeholder="СИ, как эталон"
                  :value="$store.getters.user.ci_as_name"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                  label="ГЭТ"
                  placeholder="ГЭТ"
                  :value="$store.getters.user.get"
                />
              </CCol>
            </CRow>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

  </div>
</template>

<script>
export default {
  name: 'Forms',
  data () {
    return {
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        {
          value: 'some value',
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  methods: {
    validator (val) {
      return val ? val.length >= 4 : false
    }
  }
}
</script>
